/* eslint-disable no-irregular-whitespace */
import React from 'react'
import { Hero } from '../../components/Hero'
import Layout from '../../components/Layout'
import CTASection from '../../components/CTASection'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Feature } from '../../components/Feature'
import Block from '../../components/Block'
import Page from '../../components/Page'
import MainDemoRequestCTA from '../../components/MainDemoRequestCTA'
import Customers from '../../components/CustomerLogos'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import MetaImage from '../../assets/images/sleuth-meta.png'
import { LeadHeading } from '../../components/LeadHeading'
import { Link } from 'gatsby'
import QuoteBlock from '../../components/blocks/QuoteBlock'

const SwarmiaVsSleuth = () => {
  const SwarmiaVsSleuthImages = useStaticQuery(graphql`
    query {
      feature1: file(relativePath: { eq: "images/DORA-metrics.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature2: file(
        relativePath: { eq: "images/investment-distribution/feature-2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature3: file(
        relativePath: { eq: "images/engineering-metrics/feature-health.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
    }
  `)
  return (
    <Layout
      title="Sleuth vs. Swarmia"
      description="Explore the main differences between Sleuth and Swarmia to find out which tool is a better fit for your engineering organization."
      metaImage={MetaImage}
    >
      <Hero
        background={
          <StaticImage
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: -1,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            src="../../assets/images/backgrounds/swarmia-space.png"
            aria-hidden="true"
            alt=""
            quality={90}
          />
        }
      >
        <Block type="padding-medium">
          <h1>Sleuth vs. Swarmia</h1>
          <div className="hero-description">
            <p className="large_text">
              Sleuth focuses on monitoring DORA metrics, but the reality is that
              you need more than that to improve engineering productivity. If
              you&#39;re looking for a more holistic way to measure and improve
              your engineering organization, Swarmia is probably a better
              alternative.
            </p>
          </div>
          <MainDemoRequestCTA />
        </Block>
      </Hero>
      <Page type="layout-index">
        <Block size="large" type="padding-bottom-small">
          <LeadHeading
            title="What are the main differences between Swarmia and Sleuth?"
            description="The biggest difference between Swarmia and Sleuth is the breadth of software development insights. Where Sleuth focuses solely on DORA metrics, Swarmia provides a complete set of key engineering metrics and ways to continuously improve."
          />
          <div className="features full-width-images">
            <Feature
              type="feature feature-grid-right"
              heading="Accurately track DORA metrics"
              image={
                SwarmiaVsSleuthImages.feature1.childImageSharp.gatsbyImageData
              }
              imageAlt="Accurately track DORA metrics"
            >
              <p>
                Both Swarmia and Sleuth accurately measure all DORA metrics out
                of the box. For instance, both tools calculate lead time for
                changes from the first commit to deploy to production and
                capture also non-incident failures when measuring change failure
                rate.
              </p>
              <p>
                Both allow sending deployment information with a variety of
                methods. With Swarmia you can use either PR merges, GitHub
                checks, or API as the deployment source. The API lets you
                integrate Swarmia with practically any solution.
              </p>
              <p>
                <Link to="/dora-metrics/">
                  Read more about the DORA metrics in Swarmia →
                </Link>
              </p>
            </Feature>
            <Feature
              type="feature feature-grid-left"
              heading="Go beyond DORA"
              image={
                SwarmiaVsSleuthImages.feature2.childImageSharp.gatsbyImageData
              }
              imageAlt="Go beyond DORA"
            >
              <p>
                Sleuth focuses on DORA metrics and DORA alone, whereas Swarmia
                compiles key engineering metrics from your source code hosting
                and issue tracker. With Swarmia, you&#39;ll quickly understand
                your organization&#39;s and team&#39;s current state, which
                helps you remove bottlenecks and improve productivity.
              </p>
              <p>
                Swarmia&#39;s insights bring value to every level of your
                organization, whether you&#39;re a developer, manager, or
                leader. You can drill down into any aggregate metric to quickly
                understand what it consists of and how to improve it in
                practice.
              </p>
              <p>
                <Link to="/engineering-metrics/">
                  Read more about key engineering metrics →
                </Link>
              </p>
            </Feature>
            <Feature
              type="feature feature-grid-right"
              heading="Use Working Agreements to improve as a team"
              image={
                SwarmiaVsSleuthImages.feature3.childImageSharp.gatsbyImageData
              }
              imageAlt="Use Working Agreements to improve as a team"
            >
              <p>
                While Sleuth is more like a dashboard, Swarmia enables
                continuous improvement by allowing you to set and track
                team-wide Working Agreements. By connecting Swarmia and Slack,
                your team will receive timely notifications that&#39;ll nudge
                you to take action when necessary.
              </p>
              <p>
                Whether you&#39;d like to follow industry best practices, like
                “avoid working alone on bigger issues”, or set metric-based
                targets like “review code in under 24 hours”, Swarmia has a
                Working Agreement for that.
              </p>
              <p>
                <Link to="/product/working-agreements/">
                  Read more about Working Agreements →
                </Link>
              </p>
            </Feature>
          </div>
        </Block>
        <QuoteBlock person="alex4" />
        <Customers />
      </Page>
      <CTASection>
        Swarmia is how the best engineering teams get better. Request a demo
        today.
      </CTASection>
      <FeaturedBlogArticles
        articles={[
          '/blog/balancing-engineering-investments/',
          '/blog/space-framework/',
          '/blog/dora-metrics/',
        ]}
        heading="More from our blog"
      />
    </Layout>
  )
}

export default SwarmiaVsSleuth
